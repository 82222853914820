import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import contactUsReducer from './ContactUs/reducer';
import dealsReducer from './Deals/reducer';
import dealDetailsReducer from './DealDetails/reducer';
import loanOfficerReducer from './LoanOfficer/reducer';
import newCustomerReducer from './NewCustomer/reducer';
import reportsReducer from './Reports/reducer';
import resourcesReducer from './Resources/reducer';
import userReducer from './User/reducer';
import tasksReducer from './Tasks/reducer';

export const rootReducer = combineReducers({
  contactus: contactUsReducer,
  deals: dealsReducer,
  dealdetails: dealDetailsReducer,
  loanofficer: loanOfficerReducer,
  newcustomer: newCustomerReducer,
  reports: reportsReducer,
  resources: resourcesReducer,
  user: userReducer,
  tasks: tasksReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default store;
